import React from "react"

import Layout from "../components/layout"
import KeepImage from "../components/keep-that-image"
import KeepfrontImage from "../components/keep-fep-image"
import KnuckleheadImage from "../components/knucklehead-image"
import SEO from "../components/seo"

const BooksPage = () => (
  <Layout>
    <SEO title="Books" />
    <h1>Books by Adam Smyer</h1>
    <article className="book-article">
      <h2>
        You Can Keep That to Yourself: A Comprehensive List of What Not to Say
        to Black People, for Well-Intentioned People of Pallor
      </h2>
      <figure className="quote">
        <blockquote>
          A bitingly humorous compendium of the absurd subtle racism of the
          American workplace.
        </blockquote>
        <figcaption>
          —
          <cite>
            <a
              href="https://www.kirkusreviews.com/book-reviews/adam-smyer/you-can-keep-yourself/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kirkus Reviews
            </a>
          </cite>
          , July 14, 2020
        </figcaption>
      </figure>
      <figure style={{ maxWidth: "480px", marginBottom: "1.45rem" }}>
        <KeepImage />
      </figure>
      <figure className="quote">
        <blockquote>
          Each entry is designed to strip away the hypocrisy and half-truths of
          these cultural exchanges by laughing at them. Smyer’s hilarious
          sampler offers astute observations on race and culture.
        </blockquote>
        <figcaption>
          —
          <cite>
            <a
              href="https://www.publishersweekly.com/9781617758966"
              target="_blank"
              rel="noopener noreferrer"
            >
              Publishers Weekly
            </a>
          </cite>
          , June 15, 2020
        </figcaption>
      </figure>
      <figure className="quote">
        <blockquote>
          It is a fast, scathingly funny, profane, illuminating, punch-packing
          guide to racial etiquette. It's like Dave Chappelle and Emily Post had
          a baby.
        </blockquote>
        <figcaption>
          —
          <a
            href="https://www.facebook.com/watch/?v=3404600072940389&extid=mJUbXmj1GhQNEdte"
            target="_blank"
            rel="noopener noreferrer"
          >
            Susan Jane Gilman
          </a>
          , author of <cite>Donna Has Left the Building</cite>
        </figcaption>
      </figure>
      <figure style={{ maxWidth: "480px", marginBottom: "1.45rem" }}>
        <KeepfrontImage />
      </figure>
      <div className="store-list">
        <p>
          <span style={{ fontStyle: `italic` }}>
            You Can Keep That to Yourself
          </span>{" "}
          is available from many online and local stores, including the
          following:
        </p>
        <ul>
          <li>
            <a
              href="http://www.akashicbooks.com/catalog/you-can-keep-that-to-yourself/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Akashic Books
            </a>
          </li>
          <li>
            <a
              href="https://www.amazon.com/Keep-That-Yourself-Comprehensive-Well-Intentioned/dp/1617758965/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Amazon
            </a>
          </li>
          <li>
            <a
              href="https://apple.co/31RRw44"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple Books
            </a>
          </li>
          <li>
            <a
              href="https://www.barnesandnoble.com/w/you-can-keep-that-to-yourself-adam-smyer/1136382238"
              target="_blank"
              rel="noopener noreferrer"
            >
              Barnes & Noble
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/books/details/Adam_Smyer_You_Can_Keep_That_to_Yourself?id=6OneDwAAQBAJ"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play
            </a>
          </li>
          <li>
            <a
              href="https://www.greenlightbookstore.com/book/9781617758966"
              target="_blank"
              rel="noopener noreferrer"
            >
              Greenlight Bookstore
            </a>
          </li>
          <li>
            <a
              href="https://www.indiebound.org/book/9781617758966"
              target="_blank"
              rel="noopener noreferrer"
            >
              IndieBound (independent local US bookstores)
            </a>
          </li>
          <li>
            <a
              href="https://www.kobo.com/us/en/ebook/you-can-keep-that-to-yourself-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kobo
            </a>
          </li>
          <li>
            <a
              href="https://www.penguinrandomhouse.com/books/665338/you-can-keep-that-to-yourself-by-adam-smyer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Penguin Random House (audiobook, read by Donald Faison)
            </a>
          </li>
        </ul>
      </div>
    </article>
    <article>
      <h2>Knucklehead</h2>
      <p>
        Shortlisted for the 2018 Ernest J. Gaines Award for Literary Excellence
      </p>
      <figure className="quote">
        <blockquote>
          Adam Smyer’s Knucklehead is so smart, so wildly, uncategorizably
          original, such a flat-out revelation from page to page, you almost
          forget how savagely funny and fearless the author actually is.
          Knucklehead is the kind of book you don’t just admire, it’s the kind
          that makes you want to buy in bulk and jam into the hands of loved
          ones and strangers as you scream in their faces, ‘Read this or remain
          clueless!‘ In a feat of fierce literary magic, the author’s 1990s Bay
          Area and NYC peel back the curtain on our own Trump-stained era,
          exposing, in loving detail, the fine line between salvation and
          self-destruction that defines the times. No one who reads Knucklehead
          will ever think the same about relationships, about family, about
          race, class, or the business of remaining sane—and human—in the world
          of crazy-making, soul-defiling choices we now inhabit. I straight-up
          loved this book.
        </blockquote>
        <figcaption>
          —Jerry Stahl, author of <cite>Permanent Midnight</cite>
        </figcaption>
      </figure>
      <figure style={{ maxWidth: "480px", marginBottom: "1.45rem" }}>
        <KnuckleheadImage />
      </figure>
      <figure className="quote">
        <blockquote>
          From page one, Knucklehead is a literary punch in the face. Adam
          Smyer’s exploration of rage is unflinching, brave, and absolutely
          brilliant. There’s so much energy in this debut you could put it in
          your tank and drive on it.
        </blockquote>
        <figcaption>
          —Mat Johnson, author of <cite>Loving Day</cite>
        </figcaption>
      </figure>
      <figure className="quote">
        <blockquote>
          Adam Smyer is an incendiary new voice who announces himself with the
          force of a Category 5 hurricane. A staggering, unforgettable debut.
        </blockquote>
        <figcaption>
          —Arthur Nersesian, author of <cite>The Fuck-Up</cite>
        </figcaption>
      </figure>
      <figure className="quote">
        <blockquote>
          While loss and loneliness are at its core, “Knucklehead” is a
          mordantly funny book.
        </blockquote>
        <figcaption>
          —
          <cite>
            <a
              href="https://www.sfchronicle.com/books/article/Knucklehead-by-Adam-Smyer-12738316.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              San Francisco Chronicle
            </a>
          </cite>
          , March 8, 2018
        </figcaption>
      </figure>
      <figure className="quote">
        <blockquote>
          Here is a list of things you’ll need to read this book: ample space
          for stretching out the side stitches you’ll get from laughter; half a
          box of tissues for the most gripping and harrowing dramas at the heart
          of the novel; a fresh stress ball for the tense situations the
          protagonist finds himself in (both of his own doing and not); and just
          a bit of that space in your heart to see people, in all their
          complexity, trying to do their best.
        </blockquote>
        <figcaption>
          —
          <cite>
            <a
              href="https://www.post-gazette.com/ae/books/2018/08/11/Adam-Smyer-Knucklehead/stories/201807190020"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pittsburgh Post-Gazette
            </a>
          </cite>
          , August 11, 2018
        </figcaption>
      </figure>
      <figure className="quote">
        <blockquote>
          This book is bold in how it treats the reader as an insider to the
          reality of American blackness. It can be, in turns, lyrically
          poignant, cynical, hilarious, and infuriating.
        </blockquote>
        <figcaption>
          —
          <cite>
            <a
              href="https://www.forewordreviews.com/reviews/knucklehead-review.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Foreword Reviews
            </a>
          </cite>
          , January/February 2018 (starred review)
        </figcaption>
      </figure>
      <figure className="quote">
        <blockquote>
          While not strictly a crime novel, Smyer’s debut Knucklehead does
          contain a whole lot of guns, violence, and rage, as well as plenty of
          love and sadness. A black lawyer in the late 80s through the mid-90s
          deals with micro and macro aggressions from a society determined to
          treat him as a criminal. Also, there are cats. Lots of cats.
        </blockquote>
        <figcaption>
          —
          <cite>
            <a
              href="https://crimereads.com/the-most-anticipated-crime-mystery-and-thriller-titles-of-2018/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Literary Hub
            </a>
          </cite>
          , The Most Anticipated Crime, Mystery, and Thriller Titles of 2018,
          January 11, 2018
        </figcaption>
      </figure>
      <div className="store-list">
        <p>
          <span style={{ fontStyle: `italic` }}>Knucklehead</span> is available
          from many online and local stores, including the following:
        </p>
        <ul>
          <li>
            <a
              href="http://www.akashicbooks.com/catalog/knucklehead/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Akashic Books
            </a>
          </li>
          <li>
            <a
              href="https://www.amazon.com/Knucklehead-Adam-Smyer/dp/1617755877"
              target="_blank"
              rel="noopener noreferrer"
            >
              Amazon
            </a>
          </li>
          <li>
            <a
              href="https://apple.co/2E5ACqw"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple Books
            </a>
          </li>
          <li>
            <a
              href="https://apple.co/30SvWgv"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple Books audiobook
            </a>
          </li>
          <li>
            <a
              href="https://www.indiebound.org/book/9781617755873"
              target="_blank"
              rel="noopener noreferrer"
            >
              IndieBound (independent local US bookstores)
            </a>
          </li>
          <li>
            <a
              href="https://www.kobo.com/ww/en/ebook/knucklehead-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kobo
            </a>
          </li>
          <li>
            <a
              href="https://www.powells.com/book/knucklehead-9781617755873"
              target="_blank"
              rel="noopener noreferrer"
            >
              Powell's
            </a>
          </li>
        </ul>
      </div>
    </article>
  </Layout>
)

export default BooksPage
