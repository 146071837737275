import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const KeepfrontImage = () => {
  const data = useStaticQuery(graphql`
    query {
      keepEndpaper: file(relativePath: { eq: "keep-endpaper.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.keepEndpaper.childImageSharp.fluid}
      alt="Front endpaper of the book 'You Can Keep That To Yourself', printed with words including 'articulate' and 'hair'"
    />
  )
}

export default KeepfrontImage
